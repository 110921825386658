<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <menuCar  type="2"/>

        <div class="shop_carConfirm">
            <div class="shop_carSuccess">
                <i class="el-icon-success"></i>
                <span class="text">订单支付已完成</span>
                <span class="desc">订单结果可能存在延迟，请稍后刷新订单页面查询订单支付结果.</span>
<!--                <span class="no">订单号：<template v-for="(item, index) in listData"><span v-if="Number(index) > 0" style="margin-left: 16px" :key="index"></span>{{item.detailCode}}</template></span>-->
                <div class="btnGroup">
                    <el-button type="primary" @click="$router.replace('/index')">返回商城首页</el-button>
                    <el-button @click="$router.replace('/shop/order')">前往查询订单</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  const menuCar = () => import('./menu')
  export default {
    name: "shopOrderSuccess",
    data () {
      return {
        listData: []
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      // this.init()
    },
    methods: {
      init () {
        let data = JSON.parse(sessionStorage.getItem('uploadOrderData'))
        this.listData = data.orderDetails
      },
    },
    components: {
      menuCar
    }
  }
</script>
